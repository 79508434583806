@import './table.scss';
@import './card.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './drawer.scss';
@import './tooltip.scss';
@import './accordion.scss';
@import './search_filter.scss';
@import './reports.scss';
@import './scrollbar.scss';


ion-content {
  --ion-background-color: #fff;
}

/* Backdrop styles */
.MuiBackdrop-root {
  background: rgba(72, 99, 155, 0.85) !important;
}
span,div,p{

}
/* Input Styles */
.MuiFormHelperText-contained {
  margin: 0px !important;
  background: none !important;
}

.MuiPaginationItem-root {
  color: var(--title-color) !important;
}
.ahf-input-amount {
  border: 1px solid var(--filter-border) !important;
}
.sc-ion-label-md-h,
.sc-ion-label-md-s {
  //color: var(--title-color) !important;
}
.MuiInputBase-root .MuiFormControl-root,
.MuiOutlinedInput-root,
input {
  background: inherit !important;
  color: #fff;
  border-radius: 10px;
  border: 0px solid var(--filter-border) !important;
}

.MuiInputBase-root .Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}
.sc-ion-input-md-h input {
  border-radius: 10px !important;

}

.MuiInputBase-root {
  color: var(--input-text-color) !important;

  font-size: 14px;
  height: 48px;
  padding: 0;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid var(--ion-background);
  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 15px;

    padding: 14px 16px;
    @media screen and (max-width:720px) {
      padding: 14px 4px;
      font-size: 12px;
    }
  }
  .MuiSvgIcon-root {
    color: var(--input-text-color);
  }
}

input::placeholder {
  // border: 1px solid var(--ion-background) !important;
  color: var(--title-color);

}

.sc-ion-input-md-h input {
  border-radius: 10px !important;
  height: 30px;

}
.ion-label,
.input-label {
  color: var(--title-color);
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;

  font-weight: normal;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-text-color) !important;
  -webkit-transition: background-color 9999s ease-out !important;
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 2px solid var(--ion-nav-card-background);
}
.no-data {
  color: #000;
}
.MuiTab-root {
  text-transform: none;
}
.MuiTabs-indicator {
  display: none;
}

/** Modal styles **/
.modal-title {
  .MuiTypography-root {
    font-size: 32px !important;
  }
}

.modal-content-ctn {
  .input-control {
    border: 1px solid var(--filter-border);
  }

  .num-control input:focus {
    outline: none;
  }
}

.mouse-pointer {
  cursor: pointer;
}

/* Punter reports styles */
.admin-module-ctn {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
  overflow-x: hidden;
}

.full-width {
  width: 100%;
}

.no-txt-wrap {
  white-space: nowrap !important;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

a {
  color: #000;
}

.b-text {
  color: var(--text-color);
  font-weight: bold;
  text-transform: capitalize;
  text-decoration: underline;
}

.profit,
.success {
  color: var(--ion-color-success) !important;
}

.loss {
  color: #ff6666 !important;
}
.error-msg {
  color: var(--ion-color-danger) !important;
}

.m-link {
  cursor: pointer !important;
}

@media only screen and (max-width: 720px) {
  .web-view {
    display: none !important;
  }
}

@media only screen and (min-width: 721px) {
  .mob-view {
    display: none !important;
  }
}

.site-logo {
  width: var(--logo-xs-width);
  height: var(--logo-xs-height);
}

.select-text {
  color: var(--text-color) !important;
}

.MuiAppBar-colorPrimary {
  background-color: var(--table-head) !important;
  z-index: 9999;
}

.MuiPaper-root .MuiTableBody-root .MuiSvgIcon-root {
  fill: var(--text-color) !important;
  color: var(--text-color) !important;
}

.button-small,
.button-solid {
  color: var(--active-text) !important;
  span {
    color: var(--active-text) !important;
  }
}
.tooltip-icon {
  fill: #000 !important;
}
body {
  font-family: var(--font-family) !important;
}
.MuiTableCell-body,
div,
p,
span,
ion-item,
ion-input,
ion-label {
  font-family: var(--font-family) !important;
}

.select-text {
  color: var(--text-color) !important;
  font-family: var(--font-family) !important;
}
.MuiAppBar-colorPrimary {
  background-color: var(--table-head) !important;
  z-index: 9999;
}

.button-small,
.button-solid {
  font-family: var(--font-family) !important;
  color: var(--table-head-text) !important;
  
  span {
    color: var(--table-head-text) !important;
    font-family: var(--font-family) !important;
    @media screen and (max-width:920px) {
      font-size: 13px;
    }
  }
}
body {
  font-family: var(--font-family) !important;
}
div,
p,
span {
  font-family: var(--font-family) !important;
}
.MuiPaper-root .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head {
  font-family: var(--font-family) !important;
}
.sc-ion-label-ios-h {
  font-family: var(--font-family) !important;
}
.select-text {
  font-family: var(--font-family) !important;
}

.select-text {
  flex: 1 1 0%;
  min-width: 16px;
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: var(--font-family) !important;
}
// .MuiInputBase-root .MuiFormControl-root,
// .MuiOutlinedInput-root,
// input {
//   background: var(--input-background-color) !important;
//   border: 1px solid var(--filter-border);
// }

.bagde {
  background-color: var(--ion-color-primary) !important;
  color: var(--active-text) !important;
  margin-left: 3px;
  font-size: 10px;
}
.MuiTableCell-sizeSmall {
  padding: 6px !important;
}
.MuiSelect-select:focus {
  background: none !important;
}

.MuiDialog-root .MuiDialog-container, .MuiDialog-paper .MuiDialogContent-root{
  padding: 0px !important;
}
.bl-btn-next {
  width: 140px !important;
  .button-inner {
    width: 140px !important;
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.fw-700 {
  font-weight: 700;
}

.fw-bold {
  font-weight: bold;
}

.gap-20 {
  gap: 20px;
}

.gap-5 {
  gap: 5px;
}

.dashboard-tables {
  gap: 5px;
  border: 0 !important;
  display: flex;
  flex-direction: column;
  .analytics-table-ctn {
    border: 1px solid #ddd;
    height: 50%;
    border-radius: 14px;
  }
}

.d-flex {
  display: flex;
  .header-cell-ctn {
    width: fit-content;
  }
}