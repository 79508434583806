.mva-ctn {
  border: 1px solid var(--ion-text-dark);
  border-radius: 6px;
  padding: 12px;
  margin: 2px;
  
  font-size: 16px;
  color: rgb(102, 60, 0);
  background-color: var(--text-color);
  position: fixed;
  width: calc(100% - 16px);
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none; // temporarily hidden until AWS SES is configured
}
