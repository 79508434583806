body>* {
    scrollbar-color: var(--ion-color-primary) white; /* thumb and track color */
    scrollbar-width: thin;
}
*>::-webkit-scrollbar {
    width: 0.6em !important;
    height: 0.6em !important;
}

*>::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
}

*>::-webkit-scrollbar-thumb {
    background-color: var(--ion-color-primary) !important; 
    border-radius: 20px !important;
}