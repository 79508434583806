.MuiPopover-root {
    .MuiPopover-paper {
        background: var(--ion-color-secondary);
        color: var(--text-color) !important;
        .MuiList-root,
        .MuiMenu-list {
            padding: 0px;
            background: var(--select-element-background);
            .MuiListItem-root,
            .MuiMenuItem-root {
                //color: var(--text-color) !important;
                background: var(--select-element-background);
                font-size: 14px;
                height: 48px;
                padding-left: 16px;
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(var(--text-color-contrast-rgb), 0.12);
                }
                &.Mui-selected {
                    color: var(--text-color-contrast);
                    border-left: 4px solid var(--ion-color-primary);
                }
            }
        }
    }
}
