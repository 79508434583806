// IonSelect
ion-select {
  height: 38px;
  padding: 10px 12px 7px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  background: var(--select-element-background);
  font-family: var(--font-family) !important;
  @media screen and (max-width:720px) {
    height: 35px;
  }
}

ion-select-popover {
  background: var(--select-element-background);

  div,
  p,
  span {
    font-family: var(--font-family) !important;
  }
  ion-list {
    padding: 0px !important;
    width: fit-content;
    min-width: 100%;
    overflow-x: scroll;
    
    font-size: 12px;
    line-height: 15px;
    color: var(--text-white) !important;
    font-family: var(--font-family) !important;
    background: #071123 !important;
    ion-item {
      --inner-border-width: 0 0 0 0 !important;
      font-family: var(--font-family) !important;
      color: var(--text-white) !important;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(var(--ion-text-color-contrast-rgb), 0.12);
      }
      ion-radio {
        opacity: 0;
      }
      &:hover {
        color: var(--text-white) !important;
        background: var(--ion-color-primary);
        width: 100%;
        .item-radio-checked {
          color: var(--text-white) !important;
        }
      }
      .item-radio-checked {
        color: var(--text-white) !important;
      }
      &.item-radio-checked {
        color: #000 !important;
        background: var(--select-popover-active-background);
        font-family: var(--font-family) !important;
      }
    }
  }
}

// MaterialUI Select
.MuiSelect-root {
  height: 48px;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: var(--font-family) !important;
  background: none !important;
}
