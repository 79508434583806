.reports-ctn {
  .page-title {
    color: var(--title-color);
    font-size: 26px;
    font-weight: bold;
    font-family: var(--font-family1) !important;
    @media screen and (max-width:920px){
      font-weight: normal;
      font-size: 18px;
    }
  }
  .MuiPaper-root .MuiTableHead-root {
    border: 0px !important;
    font-weight: bold;
    @media screen and (max-width:720px){
      white-space: nowrap;
    }
  }
  .MuiPaper-root .MuiTableBody-root .MuiTableCell-body {
    // text-align: left;
    font-size: 14px;
    &.text-left {
      text-align: left !important;
    }
    // @media screen and (max-width: 720px) {
    //   text-align: left !important;
    // }
  }

  // Reports Table
  .MuiPaper-root {
    background-color: var(--table-paper) !important;
    box-shadow: 0px 3.40273px 17.0137px rgba(0, 0, 0, 0.25);
    margin-top: 10px;
    box-shadow: 0 0px 0px #CCCCCC !important;
    padding: 2px;
    @media only screen and (max-width: 720px) {
      margin-top: 0px;
    }
    .MuiTableHead-root .MuiTableRow-head {
      // border-bottom: 0px !important;
      color: var(--text-table) !important;
      background: transparent !important;
      .actions-cell {
        text-align: center !important;
      }
      .MuiTableCell-head {
        font-size: 16px !important;
        font-family: var(--font-family);
        color: #fff !important;
        //border-bottom: 0px !important;
        padding: 14px 5px !important;
        text-transform: capitalize;
        // text-align: left;
        font-weight: normal;

        &.text-left {
          text-align: left;
        }
        .MuiIconButton-root {
          padding: 2px !important;
          color: var(--ion-color-primary) !important;
          font-size: 12px;
          text-transform: uppercase;
          @media screen and (max-width: 720px) {
            font-size: 11px;
          }
          svg {
            color: var(--ion-color-primary) !important;
            fill: var(--ion-color-primary) !important;
          }
        }
        @media screen and (max-width:920px){
          font-size: 12px !important;
          padding: 5px;
        }
      }
    }
    .MuiTableBody-root {
      .operator-details {
        break-after: always;
        white-space: normal;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 11px;
      }

      .lay-bg,
      .back-bg {
        &.lay-bg {
          background-color: var(--lay) !important;
        }
        background-color: var(--back) !important;
        border: 0px !important;
        .MuiTableCell-body {
          font-size: 13px;
          // border: 1px solid rgba(0, 0, 0, 0.25) !important;

          font-family: var(--font-family);
          color: var(--common-dark) !important;
          padding: 6px !important;
          align-items: center;
          text-align: center !important;
          .flag-btn {
            max-width: 50px;
            min-width: 50px;
          }
          .odds-ctn {
            display: flex;
            justify-content: center;
            align-items: center;
            .tooltip-icon {
              height: 20px;
              width: 15px;
            }
          }

          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }
      .user-management-row {
        background-color: var(--row-bg) !important;
        .MuiTableCell-body {
          border: 1px solid rgba(0, 0, 0, 0.25) !important;
          font-size: 13px;

          font-family: var(--font-family);
          color: var(--common-dark) !important;
          text-align: center !important;
          @media screen and (max-width: 720px) {
            border: 1px solid var(--row-bg) !important;
          }
        }
        @media screen and (max-width: 720px) {
          .MuiTableCell-body {
            text-align: left !important;
            //border-bottom: 0px !important;
            font-size: 12px;
          }
        }
      }
      .row-bg,
      .loss-bg,
      .profit-bg {
        .operator-details {
          break-after: always;
          max-width: 80%;
          white-space: normal;
        }

        // background: var(--profit-bg);
        // &.loss-bg {
        //   background: var(--loss-bg);
        // }
        // &.row-bg {
        //   background-color: var(--row-bg) !important;
        // }
        color: var(--text-table) !important;
        .MuiTableCell-body {
          border: 1px solid rgba(0, 0, 0, 0.25) !important;
          font-size: 13px;
          border-spacing: 30px;

          font-family: var(--font-family);
          text-align: center !important;
          color: var(--text-table) !important;
          &.date-header {
            text-align: left !important;
          }
          .flag-btn {
            max-width: 50px;
            min-width: 50px;
          }

          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }
      .MuiTableRow-body {
        font-size: 13px;
        .MuiTableCell-body {
          font-size: 13px;

          font-family: var(--font-family);
          color: var(--text-table) !important;
          text-align: center !important;

          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }
    }
  }

  .no-data-row {
    background-color: var(--table-body-background);
    width: 100%;
    border-bottom: 0px !important;
    height: 35px;
    padding-top: 15px;
    text-align: center;
    color: var(--filter-border) !important;
    div,
    p,
    span {
      color: var(--filter-border) !important;
    }
  }
}

.reports-ctn {
  .header-ctn {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      max-height: 35px;
    }
    .title {
      color: var(--title-color);
      text-transform: uppercase;
      font-size: 26px;
    }
    .download-ctn {
      margin-left: 5px;
      display: flex;
      img {
        width: 60px;
        cursor: pointer;
      }
    }
    .img-page-title-row {
      display: flex;
      flex-direction: row !important;
      align-items: center !important;
      .title-image {
        height: 28px;
        width: 28px;
        margin-right: 5px;
      }
      .img-ctn {
        img {
          margin-left: 5px;
         
        }
      }
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--title-color);
        font-size: 26px;
        font-family: var(--font-family1) !important;
        .img-ctn {
          img {
            margin-left: 5px;
            cursor: pointer;
          }
        }
        .prev-level-link {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    @media screen and (max-width:920px){
      display: block;
    }
  }

  .filters-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center !important;
    font-family: var(--font-family) !important;
    .date-filter{margin-right: 10px;}
    @media screen and (max-width:920px){
      display: block;
    }
    .img-page-title-row {
      display: flex;
      flex-direction: row !important;
      align-items: center !important;
      img {
        margin-left: 10px;
        @media screen and (max-width:920px) {
          width:50px;
          margin-left: 5px;
        }
      }
      .title-image {
        height: 28px;
        width: 28px;
        margin-right: 5px;
        margin-left: 10px;
      }

      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--title-color);
        font-size: 26px;
        font-family: var(--font-family1) !important;
        .img-ctn {
          img {
            margin-left: 5px;
            cursor: pointer;
          }
        }
        .prev-level-link {
          text-decoration: underline;
          cursor: pointer;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 20px;
        }
        @media only screen and (max-width: 920px) {
          font-size: 16px;
        }
      }
      img:hover{cursor: pointer;}
    }
    // .button-small,
    // .button-solid {
    //   width: 100%;
    // }
    .filter-item-button {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 45px;
      align-items: center;
      @media screen and (max-width:720px){
        width: 100%;
        margin: 0 auto;
      }
      .search-btn{height: 45px;}
      .refresh-btn{height: 45px;}
    }
    .mt-5 {
      margin-top: 18px;
      margin-left: 4px;
    }
    .filter-control {
      border: 1px solid var(--filter-border) !important;
    }
    .filter-item {
      .filter-control,
      .input-control {
        background-color: var(--ion-background);
        color: var(--text-color);
        width: 100%;
        border: 1px solid var(--filter-border) !important;
        margin-top: 0.1rem;
        height: 40px;
        font-family: var(--font-family) !important;
        border-radius: 6px;
        @media screen and (max-width:720px){
          width: 95%;
          margin: 0 auto;
        }
        &.readonly {
          background-color: #ccc;
        }
      }
      .clear-search {
        position: absolute;
        font-size: 13px;
        color: var(--label-color);
        font-weight: var(--font-weight);
        font-family: var(--font-family) !important;
        right: 3px;
        color: var(--ion-color-danger);
        cursor: pointer;
        margin-top: -13px;
      }

      .filter-label {
        font-size: 13px;
        text-transform: uppercase;
        color:#000;
        font-weight: var(--font-weight);
      }
    }
    .search-filter {
      margin-top: -5px;
      .clear-search {
        position: absolute;
        font-size: 13px;
        color: var(--label-color);
        font-weight: var(--font-weight);
        font-family: var(--font-family) !important;
        right: 3px;
        color: var(--ion-color-danger);
        cursor: pointer;
        margin-top: -13px;
      }
      .search-filter-label {
        text-transform: uppercase;
        font-size: 13px;
        color: #000;
        font-weight: var(--font-weight);
        font-family: var(--font-family) !important;
      }
    }
    .select-filter {
      .select-filter-label {
        font-size: 13px;
        color: #000;
        font-weight: var(--font-weight);
        text-transform: uppercase;
      }
      .select-filter-control {
        height: 48px;
        background-color: var(--ion-background-secondary);
        color: var(--text-color-white)!important;
        border: 1px solid var(--filter-border);
        width: 100%;
        border-radius: 8px !important;
        font-family: var(--font-family) !important;
        color: #fff !important;

        @media screen and (max-width:720px){
          padding: 0 10px;
        }
        svg {
          fill: var(--ion-color-primary);
          opacity: 1;
          margin-right: 5px;
        }
      }
    }
    .date-filter {
      .date-filter-label {
        font-size: 13px;
        color: #000;
        font-weight: var(--font-weight);
        text-transform: uppercase;
      }
      .readonly {
        opacity: 0.6 !important;
      }
      .date-filter-control {
        margin-top: 0.1rem;
        height: 50px;
        background-color: var(--ion-background);
        color: var(--text-color-white);
        border: 0px;
        width: 100%;
        font-family: var(--font-family) !important;
        .MuiInputBase-root {
          margin-top: 0.1rem;
          height: 50px;
          background-color: var(--ion-background-secondary);
          color: var(--text-color-white);
          border: 1px solid var(--filter-border);
          min-width: 100%;
          border-radius: 8px;
          font-family: var(--font-family) !important;
          .MuiIconButton-root {
            margin-right: 5px;
            svg {
              fill: var(--ion-color-primary);
              opacity: 1;
            }
          }

          width: 180px;
          .MuiInputBase-input,
          .MuiOutlinedInput-input {
            padding: 4px 6px;
            color: #fff !important;
          }
        }
      }
    }
  }
}
.no-data-cell{color:#000}
@media only screen and (max-width: 720px) {
  .reports-ctn {
    .header-ctn,
    .content-ctn {
      padding: 0px;
      .points-type-toggle {
        height: 32px;
        border-radius: 4px;
        .MuiBottomNavigationAction-root {
          font-size: 13px;
          line-height: 17px;
          min-width: 70px;
        }
      }
    }
    .filters-row {
      display: block;
      flex-wrap: wrap;
      line-height: 10px;
      .button-small,
      .button-solid {
        height: 32px;
      }
      .select-filter {
        margin: 0.3rem;
        flex: 0 0 47%;
        .select-filter-label {
          font-size: 12px;
        }
        .select-filter-control {
          margin-top: 0.1rem;
          width: 100%;
          font-size: 12px;
        }
      }
      .date-filter {
        margin: 0.3rem;
        flex: 0 0 47%;
        .date-filter-label {
          font-size: 12px;
        }
        .date-filter-control {
          margin-top: 0%;
          .MuiInputBase-root {
           
            width: 100%;
            .MuiInputBase-input,
            .MuiOutlinedInput-input {
              font-size: 12px;
              padding: 4px 16px;
              color: #fff !important;
            }
          }
        }
        @media screen and (max-width:920px) {
          // margin: 0;
        }
      }
      .readonly {
        background: rgba(207, 207, 207, 0.5);
      }
    }
    .header-ctn {
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 1rem !important;
font-size: 10px;
      display: flex;
      justify-content: space-between;
      .btn {
        max-height: 35px;
      }
      .img-page-title-row {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        display: flex;
        align-items: center;
        margin-top: 15px;
        margin-left: 10px;
        .title-image {
          height: 25px;
          width: 25px;
          margin-right: 5px;
          margin-left: 0px;
        }
        .title {
          font-size: 16px;
          display: flex;
          flex-direction: column;
          line-height: 22px;
          color: var(--title-color);
          //font-weight: bold !important;
          font-family: var(--font-family1) !important;
        }
      }
    }
  }
  .text-ellipsis {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.date-filter-control {
  .MuiInputBase-root {
    border: none;
    .MuiInputBase-input,
    .MuiOutlinedInput-input {
      color: var(--text-color);
      border: none !important;
    }
  }
}

.input-wrapper {
  font-family: var(--font-family) !important;
}

.sc-ion-label-ios-h {
  font-family: var(--font-family) !important;
}

.ahf-input-amount {
  border: 1px solid var(--filter-border) !important;
}

.bl-btn-next {
  .button-inner {
    width: 140px !important;
  }
}
@media screen and (max-width:720px){
 .ifmobile{
  margin-left: 10px;
 }
}