ion-card {
  color: var(--text-color);
  border: none !important;
  border-radius: 6px;
  @media  screen and (max-width:600px) {
    margin: 0 0 15px;
  }
  ion-card-header {
    background: var(--ion-color-primary);
    color: var(--text-color);
    ion-card-title {
      color: var(--text-color);
    }
  }
  ion-card-header {
    background: #071123;
    color:#fff;
  }
}

.data-info-card {
  border: 1px solid rgba(var(--text-color-rgb), 0.1);
  border-radius: 6px;
  background: var(--ion-color-primary-contrast);
  padding: 16px;
  
  letter-spacing: 0.01em;
  margin-left: 3px;
  margin-right: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;

  .card-label {
    color: var(--text-color);
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
  }

  .card-content {
    color: var(--text-color);
    
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.01em;
  }
}

@media only screen and (max-width: 576px) {
  .data-info-card {
    .card-label {
      font-size: 16px;
      line-height: 19px;
    }

    .card-content {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.info-card {
  border: 1px solid rgba(transparent, 0.1);
  border-radius: 20px;
  background: var(--ion-color-primary);
  padding: 2px;
  
  letter-spacing: 0.01em;
  display: flex;
  justify-content: space-between;
  cursor: default;
  @media screen and (max-width:720px){
    margin-bottom: 15px;

  }
  &.flex-column {
    flex-direction: column;
  }

  &.flex-row {
    flex-direction: row;
  }

  .card-label {
    color: #fff !important;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
  }

  .card-content {
    color: #fff !important;
    font-size: 30px;
    
    line-height: 27px;
    letter-spacing: 0.01em;
    @media screen and (max-width: 1024px) {
      font-size: 20px !important;
    }
  }
}

// Mini tabs and mobile
@media screen and (max-width: 720px) {
  .info-card {
    width: 100%;
    &.mb-size {
      padding: 0px;
      margin-left: 0;
      .card-label {
        font-size: 14px;
        
       // color: #fff !important;
      }

      .card-content {
        font-size: 20px;
        
        line-height: 24px;
        //color: #fff !important;
      }
    }

    &.flex-row {
      flex-direction: column;
    }
  }
}
.info-msg{color: #000;text-align: center;padding-top: 20px;}
