.change-pwd-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  .usr-input,
  .pwd-input {
    display: inline-grid;
    margin-top: 20px;
    width: 100%;
  }
  .MuiTypography-body1{
    color: #000;
  }
  .submit-form-btn {
    // margin-top: 20px !important;
    margin-bottom: 10px;
    width: 100%;
    height: 48px;
    
    
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .accept-terms-input {
    padding-top: 10px;
    width: 341px;

    .status-input {
      margin-top: 15px;
    }
    .MuiInputBase-root {
      border: 1px solid var(--ion-color-primary-contrast);
      padding: auto;
      .MuiInputBase-input {
        color: #fff !important;
        background-color: var(--ion-color-secondary) !important;
      }
    }

    .MuiFormHelperText-root {
      color: red;
    }

    .accept-terms-checkbox {
      color: var(--text-color) !important;
      .MuiSvgIcon-root {
        fill: #000 !important;
      }
    }
  }
}

.error-msg {
  color: var(--ion-color-danger);
  margin-top: 20px;
  text-align: center;
}
.success-msg {
  color: var(--ion-color-success);
  margin-top: 20px;
  text-align: center;
}
.user-name,
.pwd-field {
  width: 100%;
  height: 48px;
}

.input-tooltip {
  color: var(--text-color);
  font-size: 20px;
  padding-left: 4px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    width: 100%;
  }
}
