.MuiPickersBasePicker-container {
  background: var(--ion-drawer-background);
  color: #fff !important;
  .MuiPickersClockNumber-clockNumber{
    color: #fff !important;
  }
  .MuiPickersBasePicker-pickerView {
    .MuiPickersCalendarHeader-switchHeader {
      margin: 0px;
      padding: 12px;
      
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid rgba(var(--ion-text-color-contrast-rgb), 0.1);
      .MuiPickersCalendarHeader-iconButton {
        background: var(--ion-color-primary);
        color: #fff !important;
        opacity: 1;
        border-radius: 4px;
      
      }
    }
    .MuiPickersCalendarHeader-daysHeader {
      .MuiPickersCalendarHeader-dayLabel {
        padding-top: 16px;
        
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: var(--ion-color-primary) !important;
      }
    }
    .MuiPickersCalendar-transitionContainer {
      .MuiIconButton-root {
        color: var(--ion-color-primary-contrast);
        opacity: 1;
        border-radius: 4px;
      }
      .MuiPickersDay-hidden {
        color: rgba(255, 255, 255, 0.3) !important;
        opacity: 0.4 !important;
      }
      .MuiPickersDay-daySelected {
        background-color: var(--ion-color-primary) !important;
        color: var(--ion-color-primary-contrast) !important;
      }
    }
  }

  .MuiPickersDay-dayDisabled {
    // background: #dac3c3 !important;
    opacity: 0.3 !important;
  }
}
