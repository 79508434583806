.MuiDrawer-root {
  .MuiDrawer-paper {
    background: var(--ion-drawer-background);
    width: 60%;
    max-width: 210px;
    .drawer-header-row {
      background: var(--ion-background);
      border-bottom: 1px solid rgba(var(--text-color-contrast-rgb), 0.3);
      color: var(--ion-color-secondary-contrast);
      align-items: baseline;
      .close-btn {
        margin: 12px;
        margin-right: 0px;
        opacity: 1;
      }
    }
  }
}
