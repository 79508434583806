.MuiDialog-root {
  .MuiDialogActions-root {
    // background: var(--dialog-content-background) !important;
  }
  .MuiDialog-container {
    .MuiDialog-paper {
      border-radius: 14px;
      .MuiDialogTitle-root {
        background: var(--dialog-header-background);
        color: var(--ion-color-primary-contrast) !important;
        text-align: left;
        font-size: 16px !important;
        height: auto;
        
        @media screen and (min-width: 1200px) {
          height: 50px;
        }
        .MuiTypography-root {
          font-size: 16px !important;
          margin-top: -5px;
          
          font-family: var(--font-family);
        }
      }
      .modal-close-btn {
        svg {
          fill: var(--ion-color-primary-contrast) !important;
          margin-top: -5px;
          position: absolute;
          top: 18px;
          right: 8px;
        }
      }

      .light-bg-title {
        color: var(--dialog-header-background);
        text-align: center;
        max-height: 60px !important;
        .MuiTypography-root {
          color: var(--ion-color-primary-contrast);
          font-size: 20px !important;
        }
      }

      .MuiDialogContent-root {
        padding: 0px !important;
        background: var(--dialog-content-background);
        .MuiButton-containedPrimary {
          color: var(--table-head-text) !important;
          text-transform: none;
          
          margin-bottom: 10px;
        }
        .MuiButton-containedPrimary:hover {
          background-color: var(--ion-color-primary-shade);
        }
      }
    }
  }
}
.MuiDialog-root
  .MuiDialog-container
  .MuiDialog-paper
  .MuiDialogTitle-root
  .MuiTypography-root {
  color: var(--ion-color-primary-contrast) !important;
}
.MuiButton-endIcon,
.MuiButton-iconSizeMedium,
.MuiButton-label {
  color: var(--table-head-text) !important;
  font-weight: bold;
}

.MuiDialogContent-root {
  padding: 0px !important;

  .MuiButton-containedPrimary {
    text-transform: none;
    
  }

  .muibutton-containedprimary:hover {
    background: var(--ion-color-primary); //--ion-color-primary;
    color: var(--table-head-text);
  }

  .MuiButton-containedPrimary:hover {
    background-color: var(--ion-color-primary);
    color: var(--table-head-text);
  }
}


.MuiDialog-root .MuiDialog-container, .MuiDialog-paper .MuiDialogContent-root{
  padding: 0px !important;
}