
@import url('https://fonts.googleapis.com/css2?family=Istok+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@1,500&display=swap');
@import '../src/assets/global_styles/index.scss';

.router-ctn {
  width: 100%;
}

.dev-tools-msg-modal {
  .modal-content-ctn {
    background: var(--ion-card-bg);
    border: 5px solid var(--ion-color-primary);

    .dev-tools-warning-msg {
      padding: 20px;
      font-size: 24px;
      color:#fff;
    }
  }
}
