.MuiPaper-root {
  //border: 1px solid var(--filter-border);
  box-shadow: none !important;
  .MuiTable-root {
    border: 1px solid #cdcdcd;
    background-color: inherit;
   
  }
  .MuiTableHead-root {
    height: 31px;
    background: var(--report-table-header) !important;
    border: 0px !important;
    .MuiTableRow-head {
      background: var(--report-table-header) !important;
      border-bottom: 1px solid var(--filter-border) !important;
      .MuiTableCell-head {
        font-size: 13px !important;
        font-family: var(--font-family);
        background: var(--report-table-header) !important;
        color: var(--text-white) !important;
        border-bottom: 1px solid var(--report-table-border-color) !important;
        border-top: 0px !important;
        align-items: center;
        padding: 4px !important;
        .MuiIconButton-root {
          color: var(--text-white);
          opacity: 1;
          .MuiTableSortLabel-root .MuiSvgIcon-root {
            color: var(--text-white);
            opacity: 1 !important;
          }
        }
      }
    }
  }

  .MuiTableBody-root {
    background-color: var(--table-body-background);
    border: 1px solid #cdcdcd;
    .MuiTableRow-body {
      background-color: var(--table-body-background);
      .MuiTableCell-body {
        border: 1px solid var(--filter-border) !important;
      }
    }
    .MuiTableCell-body {
      color: var(--text-color) !important;
      //border: 0px !important;
      cursor: default;
      white-space: nowrap;
      font-size: 13px;
      padding: 4px !important;
    }
    .MuiSvgIcon-root {
      color: var(--ion-color-primary-contrast) !important;
    }

    .profit-bet {
      color: var(--ion-color-success) !important;
    }

    .loss-bet {
      color: var(--ion-color-lost) !important;
    }
  }
}

@media only screen and (max-width: 720px) {
  .MuiPaper-root {
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiSvgIcon-root {
          font-size: 20px;
        }
      }
      .MuiTableBody-root {
        .MuiTableCell-body {
          font-size: 12px;
          min-height: 42px;
        }
        .MuiSvgIcon-root {
          font-size: 18px;
          margin-bottom: -2px;
        }
      }
    }
  }
}
